"use client";

import Image from "next/image";

import SomethingWentWrong from "@/components/SomethingWentWrong";

export default function GlobalError({
  error,
  reset,
}: {
  error: (Error & { digest?: string }) | null;
  reset?: () => void;
}) {
  return (
    <html>
      <body>
        <section>
          <div className="flex flex-row">
            <div className="h-screen w-1/2 flex flex-col items-center justify-center bg-black -mt-2 -ms-2">
              <Image src="/images/Condoit_Logo_Reverse.png" alt="Condoit Logo" width={200} height={48} />
            </div>
            <div className="flex w-1/2 flex-col items-center">
              <SomethingWentWrong error={error} reset={reset} />
            </div>
          </div>
        </section>
      </body>
    </html>
  );
}
